import React from "react"
import Seo from "../../../components/seo"

import CoolChambers from "./ohlagdaemye-sklady"


const CoolChambersIndex = () => (

  <>
    <Seo 
      title="Строительство холодильных складов под ключ"
      description="Строительство холодильных складов под ключ в любом регионе России. Холодные склады под ключ."
      keywords="холодильный склад, холодный склад, хранение продуктов, склад холода"
    />
    <CoolChambers />
  </>
  
)

export default CoolChambersIndex